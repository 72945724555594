import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"

import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from './../../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'
import ScrollAnimation from 'react-animate-on-scroll';




const ContactPage = ({ data }) => {

  //console.log(data);

  const [jsonTeam, setJsonTeam] = useState(
    Array.from({ length: 1 }).map((_, index) =>  <div className="team"></div>)
  );


  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
//  var image = data.photos.edges[0];
  //console.log(image);

  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {

  var _jsonTeam = response.data.team
  //console.log(_jsonTeam);
  setJsonTeam(_jsonTeam);

  setIsLoaded(true);
});
}


  useEffect(() => {
    //console.log('loaded')
      loadHtml('https://discover.lilleymansion.com/app/v1/content/team');
  },[]);

  useEffect(() => {
    setIsMobile(mobile);

    if(mobile){
    //  image = data.photos.edges[1];
      //console.log(image);
    }
  },[]);




  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper" className="mt-5">


    <div className="mt-5 mb-5 text-center p-2 m-2">
    <div className="container pt-4 p-2 text-center">
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp animate__delay-.3s">
    <h1 className="mt-5 titling">LILLEY TEAM</h1>
    <p className="text-center p-2"> <a className="text-center p-2 font-lm" href="/lilley-team/patrick-roggenbau">PATRICK ROGGENBAU</a> | <a className="text-center p-2 font-lm" href="/history">HISTORY</a></p>
    </ScrollAnimation>
    </div>


    <div className="row">
    {jsonTeam.map((item, index) => (
      <div className={index <= 1 ? 'mt-3 col-12 col-md-12 text-center' : ' mt-3 col-12 col-md-4 text-center'} key={'s'+index}>
      <img width={index <= 1 ? 180 : 150} className="rounded-circle" src={'https://discover.lilleymansion.com/storage/app/media' + item.image} alt={item.name}/>
      <h3 className="text-center">{item.name}<br/><small class="bronze">{item.position}</small></h3>
      {item.visible_desc ? <div className="text-left m-3" dangerouslySetInnerHTML={{ __html: item.description }} /> : ''}
      </div>
    ))}
    </div>


    </div>
    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

ContactPage.Layout = Layout

export default ContactPage

/*export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "history"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`*/
